* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  font-family: Arial, Helvetica, sans-serif;
}

div {
  position: flex;
  z-index: 1;
}
body {
  margin-top: 0;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

h1 {
  font-size: 5rem;
  padding: 1rem 0;
}

/* //////////////////////          LANDING PAGE   //////////////////////////////////// */
.word {
  position: absolute;
  font-size: xx-large;
  color: rgba(0, 0, 0, 0.475);
  height: 100%;
  width: 100%;
  top: 300px;
  left: 300px;
  z-index: 1;
  text-decoration: none;
}
.werm {
  position: STICKY;

  height: 50px;
  width: 50px;
  z-index: 20;
  margin-bottom: -40px;
}
.cort {
  position: STICKY;
  margin-top: 5px;
  margin-right: 5px;
  height: 45px;
  width: 50px;
  z-index: 20;
  float: right;
}
.yot {
  position: ;
  margin-top: 5px;
  margin-right: 5px;
  height: 5vh;
  /* width: 2.5vw; */
  z-index: 20;
  float: right;
  top: 200px;
}
.twt {
  position: STICKY;
  margin-top: 5px;
  margin-right: 5px;
  height: 5vh;
  /* width: 2.5vw; */
  z-index: 20;
  float: right;
  top: 200px;
}
.arch {
  position: STICKY;
  margin-top: 10px;
  margin-right: 5px;
  height: 4vh;
  /* width: 2.5vw; */
  z-index: 20;
  float: right;
  top: 200px;
}
footer {
  padding: 0px 0;
  position: absolute;
  display: inline;
  bottom: 0;
  width: 100%;
  left: 0;
}
.nav-selectors {
  width: 100%;
  display: flex;
  z-index: 2;
  justify-content: flex-end;
  text-align: center;
  align-items: center;
}

.nav-link {
  display: absolute;
  justify-content: space-between;
  align-items: center;
  z-index: 3;

  text-decoration: none;
  color: rgb(0, 0, 0);
  margin-right: 5px;
}

.nav-link:hover {
  color: white;
  background-color: rgb(69, 71, 81);
}

.bg {
  overflow-x: hidden;
  position: fixed;
  width: 100vw;
  height: 150vh;
  top: -90px;
  background-repeat: repeat;
  image-rendering: optimizeQuality;
  image-rendering: crisp-edges;
}

h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}
h3 {
  padding: 1rem 0;
}

/* //////////////////////          Home Page   //////////////////////////////////// */

.pcol {
  position: relative;
  float: left;

  top: 100px;
}

.productbox {
  margin-left: 100px;
  margin-right: 60px;
  flex: 1 1 12em;
  border-radius: 25px;
  object-fit: 100%;
  position: inherit;
}

.productbox img {
  display: flex;
  max-width: 20vw;
  max-height: 17.5vh;
  margin: 6px;
  float: right;
  justify-content: center;
  animation-name: cover;
  animation-duration: 5s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  filter: grayscale(75%);
}

@keyframes cover {
  0% {
    opacity: 0;
    color: #00000000;
  }
  10% {
    opacity: 0;
    color: #00000000;
  }
  100% {
    opacity: 1;
    color: #000;
  }
}
/* //////////////////////          Product page   //////////////////////////////////// */
.productside1 {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  float: left;
  width: 33.33%;
  overflow: hidden;
}

.extraimg {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  float: left;
  width: 12%;
  overflow: hidden;
  margin-left: 5px;
}
.descript {
  display: flex;
  flex-direction: column;
  justify-content: left;
  float: left;
  width: 50%;
  flex-shrink: 0;
  flex-flow: column wrap;
}
.productimg1 {
  position: relative;
  left: 0.5vw;
  height: 100%;
  width: 100%;
  float: left;
}
.productimg2 {
  position: relative;
  left: 0.5vw;
  margin-bottom: 10px;
  width: 90.2%;
  float: left;
}
.productimg3 {
  position: relative;
  width: 90.2%;
  left: 0.5vw;
  margin-bottom: 10px;
}
.productimg4 {
  position: relative;
  width: 90.2%;
  left: 0.5vw;
}

.itemname {
  position: relative;
  font-size: 3vw;
  bottom: -25px;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.artistname {
  position: relative;
  font-size: 2.5vw;
  bottom: 5px;
  margin-left: 15px;
  margin-bottom: 10px;
}

.desc1 {
  position: relative;
  color: black;
  font-size: 1.5vw;
}
.desc2 {
  position: relative;
  color: black;
  margin-top: 10px;
  line-height: 1.2;
  text-align: left;
  font-size: 1.5vw;
  float: left;
}
.desc3 {
  position: relative;
  color: black;
  margin-top: 10px;
  line-height: 1.2;

  font-size: 1.5vw;
  text-align: left;
  float: left;
}
.add {
  background-color: #71787e8d;
  position: relative;
  float: left;
  padding: 10px 40px;
  text-align: center;
  text-decoration: none;
  display: block;
  width: 12vw;
  font-size: 1vw;
  text-wrap: nowrap;
  margin-right: 10px;
  margin-top: 10px;
}
.btn1 {
  background-color: #71787e8d;
  position: relative;
  float: left;
  padding: 10px 40px;
  text-align: center;
  text-decoration: none;
  display: block;
  width: 12vw;
  font-size: 1vw;
  text-wrap: nowrap;
  margin-right: 10px;
  margin-top: 10px;
}

.btn2 {
  background-color: #71787e8d;
  position: relative;
  float: left;
  padding: 10px 40px;
  text-align: center;
  text-decoration: none;
  display: block;
  width: 12vw;
  font-size: 1vw;
  text-wrap: nowrap;
}

.price {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.5vw;
  white-space: nowrap;
}
/* ////////////////////////// cart ///////////////////////*/

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
.form-control {
  display: block;
  width: 5vw;
  height: calc(1.5em + 1.5rem);
  padding: 0.75rem 1.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #55595c;
  background-color: #71787e8d;
  background-clip: padding-box;
  border: 0 solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-decoration: none;
  color: #fff;
}
.cartimg {
  width: 10vw;
  float: left;
  margin: 5px;
  text-decoration: none;
}
.btn-block {
  background-color: #71787e8d;
  position: relative;
  /* padding: 10px 30px; */
  text-align: center;
  text-decoration: none;
  color: #fff;
  width: 200px;
  height: 50px;
  margin-top: 10px;
  float: right;
  justify-content: right;
  margin-top: 15px;
  text-decoration: none;
  margin-right: -25;
}
.fullprice {
  position: relative;
  float: right;
  justify-content: right;
  top: 125px;
  z-index: 5;
  font-size: 1vw;
}
.img {
}
.nnp {
  position: relative;
  margin-top: 10px;
  line-height: 1.2;
  text-align: left;
  font-size: 1.5vw;
  text-decoration: none;
}
.trash {
  margin-top: 10px;
  text-decoration: none;
  height: 1px;
}
.carthead {
  position: relative;
  font-size: 5vh;
  top: 15px;
  margin-left: 5px;
}
.pname {
  text-decoration: none;
}
/* //////////////////// CHECK OUT ///////////////////////////////////// */
.shipping {
  position: relative;
  font-size: 5vw;

  z-index: 5;

  margin-left: 5px;
}
.formg {
  position: relative;
  justify-content: center;
}

.formbox {
  width: 500px;
  text-align: center;
  font-size: large;
  margin: 10px;
}
.form-label {
  position: relative;
  margin-top: 10px;
  font-size: x-large;
}
.cont {
  background-color: #71787e8d;
  position: relative;

  padding: 10px 40px;
  text-align: center;
  text-decoration: none;
  display: block;
  width: 200px;
  text-wrap: nowrap;
  margin-right: 10px;
  margin-top: 30px;
  color: white;
}
.CheckoutSteps {
  position: relative;
}
/* //////////////////////////// order screen /////////////////// */

.cont {
  background-color: #71787e8d;
  position: relative;
  padding: 10px 40px;
  text-align: center;
  text-decoration: none;
  display: block;
  width: 250px;
  text-wrap: nowrap;
  margin-right: 10px;
  color: white;
}
.sub {
  position: relative;
  top: 300px;
  display: flex;
  justify-content: center;
  align-self: center;
}
.check {
  display: flex;
  justify-content: center;
  align-self: center;
  margin: 20px;
}
.select {
  display: flex;
  justify-content: center;
  align-self: center;
}

/* /////////////////////// place order //////////////////////////// */
.shipimg {
  width: 10vw;
  float: left;
  margin: 5px;
  text-decoration: none;
  flex-direction: column;
}
.itmname {
  font-size: 1.5vw;
  white-space: nowrap;
  text-decoration: none;
}
.itemtxt {
  font-size: 1.5vw;
  white-space: nowrap;
  text-decoration: none;
}
.summary {
  display: flex;
  position: relative;
  float: right;
  justify-content: right;
  margin-right: 25px;
  font-size: 1.5vw;
  flex-direction: column;
}
.total {
  font-size: 1.5vw;
  margin-bottom: 10px;
  margin-top: 10px;
}
.sum {
  font-size: 1vw;
}
.shpp {
  margin-left: 10px;
  font-size: 2vw;
}
.shp {
  margin-left: 5px;
  font-size: 1.5vw;
}
.pay1 {
}

.pay2 {
}
.PayPalButton {
  display: flex;
  position: relative;
  float: right;
  justify-content: right;
  margin-right: 25px;
  font-size: 1.5vw;
  flex-direction: column;
  z-index: 5;
}
.paid {
  margin-left: 10px;
}
p {
  position: relative;
  z-index: 5;
  margin-left: 10px;
  bottom: 1px;
}
